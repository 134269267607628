import React from 'react'
import PropTypes from 'prop-types'

import Link from '@atoms/Link/index.js'

import { Wrapper, CoverImg, Content, Title, Category, Meta } from './styles.js'

const BlogCard = ({ data, big, vertical, noImg }) => {
  let contentText = data.content.value.document.children
    .map(block => block.type === 'paragraph' && block.children[0].value)
    .toString()

  function readingTime(content) {
    const wpm = 180
    const words = content.trim().split(/\s+/).length + 50
    const time = Math.ceil(words / wpm)
    return time
  }

  return (
    <Wrapper big={big} vertical={vertical}>
      {!noImg && (
        <Link to={`blog/${data.slug}`} title={data.title}>
          <CoverImg vertical={vertical} img={data.cover.url} />
        </Link>
      )}
      <Content big={big} vertical={vertical}>
        <Category>
          <Link
            to={`blog/category/${data.category[0].slug}`}
            title={data.category[0].name}
          >
            {data.category[0].name}
          </Link>
        </Category>
        <Title big={big}>
          <Link to={`blog/${data.slug}`} title={data.title}>
            {data.title}
          </Link>
        </Title>
        <p>{data.shortcut}</p>
        <Meta>
          {data.meta.publishedAt} | {readingTime(contentText)} mins read
        </Meta>
      </Content>
    </Wrapper>
  )
}

BlogCard.propTypes = {
  data: PropTypes.object,
}

BlogCard.defaultProps = {
  data: null,
}

export default BlogCard
