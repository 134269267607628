import styled, { css } from 'styled-components'

export const Wrapper = styled.article`
  ${({ vertical }) =>
    vertical
      ? css`
          display: grid;
          grid-template-columns: 1fr 2fr;
        `
      : css`
          display: flex;
          flex-direction: column;
          align-items: stretch;
          justify-content: stretch;
        `};
  width: 100%;
  padding: 24px 0;

  p {
    margin: 0 0 10px;
    font-size: ${({ big }) => (big ? '16px' : '14px')};
    font-weight: 400;
  }
`

export const CoverImg = styled.div`
  overflow: hidden;
  position: relative;
  display: block;
  margin: 0 auto 20px;
  width: 100%;
  font-size: 14px;
  aspect-ratio: ${({ vertical }) => (vertical ? '1.5' : '1.8')};

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    background: center center url(${({ img }) => img}) no-repeat;
    background-size: cover;
    transform: translate3d(-50%, -50%, 0) scale(1);
    transition: transform 0.3s ease;
    content: '';
  }

  &:hover::before {
    transform: translate3d(-50%, -50%, 0) scale(1.1);
  }
`

export const Content = styled.div`
  ${({ vertical }) => vertical && 'padding-left: 20px;'}
`

export const Title = styled.h3`
  margin: 10px 0;
  font-size: ${({ big }) => (big ? '44px' : '24px')};
  font-weight: 600;
  line-height: 1.2;
  transition: color 0.3s ease;

  &:hover {
    color: var(--brand-500);
  }
`
export const Category = styled.div`
  margin: 0 0 10px;
  font-size: 14px;
  font-weight: 600;

  > a {
    background: linear-gradient(
      -45deg,
      rgba(112, 245, 112, 1) 0%,
      rgba(82, 209, 57, 1) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`

export const Meta = styled.time`
  font-family: ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas,
    Liberation Mono, monospace !important;
  font-size: 12px;
  color: var(--gray);
`
