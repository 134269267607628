import React from 'react'
import { graphql } from 'gatsby'

import Main from '@templates/Main'

import TextBox from '@atoms/TextBox'
import HorWrap from '@atoms/HorWrap'
import SectionGrayMiddle from '@molecules/SectionGrayMiddle'
import SectionWhite from '@molecules/SectionWhite'
import BlogCard from '@molecules/BlogCard'
import BlogGrid from '@molecules/BlogGrid'
import BlogHotGrid from '@molecules/BlogHotGrid'

import SignupCTA from '@organisms/SignupCTA'
import FooterCTA from '@organisms/FooterCTA'

const BlogIndexPage = ({ data, transitionStatus }) => {
  const blogPostsData = data.blogPosts.nodes
  const pageData = data.pageData
  return (
    <Main seo={pageData.seoMetaTags} transitionStatus={transitionStatus}>
      <SectionGrayMiddle hero>
        <TextBox title="Blog" center>
          <h2>Browse our latest blog posts</h2>
          <p>for inspiration and knowledge</p>
        </TextBox>
      </SectionGrayMiddle>
      <SectionWhite>
        <BlogHotGrid>
          <div>
            <BlogCard big data={pageData.mainPosts[0]} />
          </div>
          <div>
            <BlogCard vertical data={pageData.mainPosts[0]} />
            <BlogCard vertical data={pageData.mainPosts[1]} />
            <BlogCard vertical data={pageData.mainPosts[2]} />
          </div>
        </BlogHotGrid>
        {/* <BlogGrid>
          {blogPostsData.map(blogPost => (
            <BlogCard data={blogPost} />
          ))}
        </BlogGrid> */}
        {/* <BlogGrid>
          {blogPostsData.slice(7).map(blogPost => (
            <BlogCard data={blogPost} noImg />
          ))}
        </BlogGrid>
        <BlogGrid>
          {blogPostsData.slice(10).map(blogPost => (
            <BlogCard data={blogPost} noImg />
          ))}
        </BlogGrid> */}
      </SectionWhite>
      <SignupCTA bgColor="gray" />
      <FooterCTA />
    </Main>
  )
}

export const query = graphql`
  query BlogPageQuery {
    pageData: datoCmsBlogPage {
      seoMetaTags {
        tags
      }
      mainPosts {
        id
        slug
        cover {
          alt
          url
        }
        category {
          name
          slug
        }
        title
        shortcut
        lead
        meta {
          createdAt
        }
        content {
          blocks
          links
          value
        }
      }
    }
    blogPosts: allDatoCmsBlogPost(
      sort: { order: DESC, fields: meta___publishedAt }
      limit: 30
      filter: { meta: { status: { eq: "published" } } }
    ) {
      nodes {
        id
        slug
        cover {
          alt
          url
        }
        category {
          name
          slug
        }
        title
        shortcut
        lead
        meta {
          publishedAt(formatString: "DD/MM/YY")
          status
        }
        content {
          blocks
          links
          value
        }
      }
    }
  }
`

export default BlogIndexPage
