import React from 'react'
import PropTypes from 'prop-types'

import { Wrapper } from './styles.js'

const BlogHotGrid = ({ children }) => {
  return <Wrapper>{children}</Wrapper>
}

BlogHotGrid.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
}

BlogHotGrid.defaultProps = {
  children: null,
}

export default BlogHotGrid
