import styled from 'styled-components'

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 30px;
  margin: 60px auto;
  width: 100%;
  max-width: 1123px;

  ${({ theme }) => theme.media.s} {
    grid-template-columns: repeat(3, 1fr);
  }
`
